import React from "react";
import { useParams } from "react-router-dom";
import CreatePackageForm from "../../components/admin/CreatePackageForm";

const CreatePackage = () => {
  const { id } = useParams();

  return (
    <div className="admin-dashboard">
      <CreatePackageForm packageId={id} />
    </div>
  );
};

export default CreatePackage;