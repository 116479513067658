import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PackageCard from "../components/featured/PackageCard";
import useFetch from "../hooks/useFetch";
import searchImage from "./../assets/search.png";

const SearchResultsList = () => {
  const { state } = useLocation();
  const { destination, date, maxPrice } = state || {};

  const [currentPage, setCurrentPage] = useState(1);

  const queryParams = new URLSearchParams({
    destination: destination || "",
    date: date || "", // Avoid `undefined`
    maxPrice: maxPrice || 0,
    page: currentPage,
    limit: 10,
  });

  const { data: response, loading, error, reFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/packages/getSearchedPackages?${queryParams.toString()}`
  );

  // Extract data and pagination from the response
  const packages = response?.data || []; // Safely handle undefined data
  const pagination = response?.pagination || {};

  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the page
    reFetch(); // Refetch data for the new page
  };

  if (loading) return <div>Loading, please wait...</div>;
  if (error) return <div>Error loading results.</div>;

  const handleClick = () => {
    reFetch();
  };

  return (
    <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10">
      <section className="container">
        <h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold">
          Package Search Results
        </h1>
        {packages.length === 0 ? (
          <div className="text-center py-10">
            <img
              src={searchImage} // Replace with your "no results" image
              alt="No results found"
              className="mx-auto w-1/4"
            />
            <p className="text-lg mt-4">We couldn’t find any packages matching your search. Try a different destination or date!</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {packages.map((item, index) => (
              <PackageCard key={index} {...item} />
            ))}
          </div>
        )}
        {/* Pagination Section */}
        {pagination.totalPages > 1 && (
          <div className="flex justify-center mt-8">
            <button
              className="px-4 py-2 mx-2 bg-primary text-white rounded-lg"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: pagination.totalPages }).map((_, idx) => (
              <button
                key={idx}
                className={`px-4 py-2 mx-1 rounded-lg ${
                  currentPage === idx + 1
                    ? "bg-secondary text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handlePageChange(idx + 1)}
              >
                {idx + 1}
              </button>
            ))}
            <button
              className="px-4 py-2 mx-2 bg-primary text-white rounded-lg"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pagination.totalPages}
            >
              Next
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default SearchResultsList;