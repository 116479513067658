import { useEffect, useState } from "react";
import axios from "axios";

const useFetch = (url) => {
  console.log(url);
  const [data, setData] = useState(null); // Initialize as null instead of []
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // Initialize as null to store actual error if any

  useEffect(() => {
    if (!url) return; // Return early if URL is empty

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url);
        setData(res.data);
        setError(null); // Reset error if the fetch is successful
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false); // Ensure loading is set to false in both cases
      }
    };
    fetchData();
  }, [url]);

  const reFetch = async () => {
    setLoading(true);
    try {
      const res = await axios.get(url);
      setData(res.data);
      setError(null); // Reset error if the fetch is successful
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false); // Ensure loading is set to false in both cases
    }
  };

  return { data, loading, error, reFetch };
};

export default useFetch;
