import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const popularDestinations = [
  'South Africa',
  'Maldives, Islands',
  'Bali, Indonesia',
  'Phuket, Thailand',
  'Hawaii, USA',
  'Fiji, Islands',
  'Kyoto, Japan',
  'Rome, Italy',
  'Cairo, Egypt',
  'Machu Picchu, Peru',
  'Istanbul, Turkey',
  'Queenstown, New Zealand',
  'Patagonia, Argentina/Chile',
  'Banff, Canada',
  'Torres del Paine, Chile',
  'New York City, USA',
  'London, UK',
  'Dubai, UAE',
  'Paris, France',
  'Singapore',
  'Zermatt, Switzerland',
  'Aspen, USA',
  'Reykjavik, Iceland',
  'Whistler, Canada',
  'Nagano, Japan',
  'Santorini, Greece',
  'Seychelles, Islands',
  'Tahiti, French Polynesia',
  'Zanzibar, Tanzania',
  'Boracay, Philippines',
  'Orlando, USA',
  'Gold Coast, Australia',
  'Tokyo, Japan',
  'Barcelona, Spain',
  'Venice, Italy',
  'Amalfi Coast, Italy',
  'Bruges, Belgium',
  'Cinque Terre, Italy',
  'Lake Como, Italy',
  'Sydney, Australia',
  'Bangkok, Thailand',
  'Vienna, Austria',
  'Prague, Czech Republic',
  'Victoria Falls, Zimbabwe/Zambia',
  'Marrakech, Morocco',
  'Kilimanjaro, Tanzania',
  'Masai Mara, Kenya',
  'Okavango Delta, Botswana',
  'Addis Ababa, Ethiopia',
  'Accra, Ghana',
  'Lamu Island, Kenya',
  'Windhoek, Namibia',
  'Dakar, Senegal',
  'Casablanca, Morocco',
  'Abidjan, Ivory Coast',
  'Cotonou, Benin',
  'Victoria, Seychelles',
  'Port Louis, Mauritius',
  'Rwanda, Kigali',
  'Bazaruto Archipelago, Mozambique',
  'Tunis, Tunisia'
];

const Hero = () => {

  const navigate = useNavigate();
  const [destination, setDestination] = useState('');
  const [date, setDate] = useState('');
  const [maxPrice, setMaxPrice] = useState(500000);

  const handleSearch = () => {
      navigate("/SearchResultsList", { state: { destination, date, maxPrice } });
  };

  return (
    <div className=" bg-black/20 h-full">
      <div className="h-full flex justify-center items-center p-4 bg-primary/10">
        <div className="container grid grid-cols-1 gap-4">
          <div className="text-white">
            <p data-aos="fade-up" className="text-sm">
              Our Packages
            </p>
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="font-bold text-3xl"
            >
              Search Your Destination
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            className="space-y-4 bg-white rounded-md p-4 relative"
          >
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 py-3">
              <div>
                <label htmlFor="destination" className="opacity-70">
                  Destination
                </label>
                <select
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                  name="destination"
                  id="destination"
                  className="w-full bg-gray-100 my-2 range accent-primary focus:outline-primary focus:outline outline-1 rounded-full p-2"
                >
                  <option value="" disabled>Select a destination</option>
                  {popularDestinations.map((destination, index) => (
                    <option key={index} value={destination}>
                      {destination}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="destination" className="opacity-70">
                  Any Date From
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  name="date"
                  id="date"
                  className="w-full !placeholder-slate-400 bg-gray-100 my-2 rounded-full focus:outline-primary focus:outline outline-1 p-2"
                />
              </div>
              <div>
                <label htmlFor="destination" className="opacity-70 block">
                  <div className="w-full flex justify-between items-center">
                    <p>Select Max Budget per person</p>
                  </div>
                </label>
                <div className=" bg-gray-100 p-1 mt-2 rounded-lg flex items-center justify-center ">
                  <select
                    name="destination"
                    id="destination"
                    className="appearance-none w-full rounded-lg h-8"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  >
                    <option value="200000">R 500 000 or less</option>
                    <option value="200000">R 400 000 or less</option>
                    <option value="200000">R 300 000 or less</option>
                    <option value="200000">R 200 000 or less</option>
                    <option value="150000">R 150  000 or less</option>
                    <option value="100000">R 100000 or less</option>
                    <option value="65000">R 65 000 or less</option>
                    <option value="40000">R 40 000 or less</option>
                    <option value="30000">R 30 000 or less</option>
                    <option value="20000">R 20 000 or less</option>
                    <option value="15000">R 15 000 or less</option>
                    <option value="12000">R 12 000 or less</option>
                  </select>
                  <div className="hidden pointer-events-none absolute inset-y-0 right-0 sm:flex items-center px-2 mr-3 mt-5 text-gray-700">
                    <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button className="bg-gradient-to-r from-primary to-secondary text-white hover:scale-105 px-4 py-2 rounded-full duration-200 absolute -bottom-5 left-1/2 -translate-x-1/2"
              onClick={handleSearch}
            >
              Search Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
